module.exports = {
  columns: [{ key: 'name', label: 'nome', filter: true }],
  model: {
    name: '',
  },
  schema: {
    fields: [
      {
        type: 'custom-input',
        options: { uppercase: true },
        label: 'Nome',
        model: 'name',
        placeholder: 'Ex: pesado',
        required: true,
      },
    ],
  },
  viewDataTypes: [{ key: 'name', label: 'Nome' }],
}
