<template>
  <div class="home">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Novo tipo</span>
    </button>
    <b-table
      :data="allVehicleTypes"
      ref="table"
      paginated
      per-page="10"
      :striped="true"
      :debounce-search="1000"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="model"
        label="Modelo"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.name }}</b-table-column
      >

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>
    </b-table>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Modelo *">
              <b-input required v-model="model.name"></b-input>
            </b-field>

            <b-button type="button field is-info" @click="save" class="mt-10"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/vehicle-types/store/service'
import VehicleType from '../models/vehicle-type'

export default {
  name: 'VehicleTypes',
  data() {
    return {
      model: VehicleType.model,
      isCardModalActive: false,
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('vehicleTypes', ['allVehicleTypes']),
  },
  methods: {
    ...mapActions('vehicleTypes', ['getAllVehicleTypes']),
    async init() {
      await this.getAllVehicleTypes()
    },
    async save() {
      const vehicleType = { ...this.model }

      try {
        const save = vehicleType.id
          ? service.updateVehicleType(vehicleType)
          : await service.saveVehicleType(vehicleType)
        await this.$success('Tipo de Veículo')
        location.reload(true)
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    onEdit(item) {
      this.model = { ...item }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover o tipo de veículo?')

        if (result.isConfirmed) {
          await service.deleteVehicleType(id)

          await this.$delete('Tipo de Veículo')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
  },
}
</script>

<style scoped></style>
